import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";
import { FaLevelUpAlt } from "react-icons/fa";
import { createTheme, ThemeProvider } from '@mui/material/styles'; 

// Pages
import HomeDefault from "./pages/HomeDefault";
import MaterialRecovery from './pages/MaterialRecovery';
import AboutOlaCircles from './pages/AboutOlaCircles';

import AboutFacility from './pages/AboutFacility';
import Resources from './pages/Resources';

import About from "./pages/About";
import ServiceTwo from './pages/ServiceTwo';

import Experts from './pages/Experts';
import Team from './pages/Team';


import BlogDetails from './pages/BlogDetails';

import Error from "./pages/Error";
import ScrollToTopRoute from './components/scroll-to-top-route/ScrollToTopRoute';
import WhyChoseUsOne from './components/why-choose-us/WhyChoseUsOne';

// CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/vendor/slick/slick.css";
import './assets/css/default.css';
import './assets/css/main.css';
import './assets/css/responsive.css';
import './assets/vendor/modal-video/modal-video.min.css';
import "../node_modules/yet-another-react-lightbox/dist/styles.css";





// Define your custom theme
const theme = createTheme({
  palette: {
    // primary: {
    //   light: '#757ce8',
    //   main: '#3f50b5',
    //   dark: '#002884',
    //   contrastText: '#fff',
    // },
    // secondary: {
    //   light: '#ff7961',
    //   main: '#f44336',
    //   dark: '#ba000d',
    //   contrastText: '#000',
    // },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}> {/* Wrap with ThemeProvider */}
      <Router>
        <ScrollToTopRoute />
        <Routes>
          <Route path="/" exact element={<HomeDefault />} />
          <Route path="/material-recovery" exact element={<MaterialRecovery />} />
          <Route path="/about-olacircles" exact element={<AboutOlaCircles />} />

          <Route path="/about-facility" exact element={<AboutFacility />} />
          <Route path="/resources" exact element={<Resources />} />
          <Route path="/partners" exact element={<ServiceTwo />} />
          <Route path="/about" exact element={<About />} />
    
          <Route path="/experts" exact element={<Experts />} />
          <Route path="/team" exact element={<Team />} />
          <Route path="/#our-community" exact element={<WhyChoseUsOne />} />
  
          <Route path="/success-stories" exact element={<BlogDetails />} />
          <Route path="*" element={<Error />} />
        </Routes>
        
        <ScrollToTop className="scrollUp" smooth top="1500" component={<FaLevelUpAlt />} />
      </Router>
   
    </ThemeProvider> 
    
  );
}

export default App;
