import React from 'react';
import { FaLinkedinIn } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import TeamStyleThreeImg1 from '../../assets/images/team/ralph.JPG';
import TeamStyleThreeImg2 from '../../assets/images/team/yasser.jpg';

const TeamStyleThreeData = [
    {
        id: uuidv4(),
        img: TeamStyleThreeImg1,
        name: 'Ralph Sbeih',
        position: 'Circular Economy',
        linkedinLink: 'https://www.linkedin.com/in/ralph-sbeih-7b585a27',
    },
    {
        id: uuidv4(),
        img: TeamStyleThreeImg2,
        name: 'Yasser Abu Naser',
        position: 'Water Circularity',
        linkedinLink: 'https://www.linkedin.com/in/yaser-abunnasr-796b025',
    },
];
const TeamStyleThreeItem = ({ img, name, position, linkedinLink }) => {
    return (
        <div className="single-team-style-1 text-center">
            <div className="single-team-style-1-img">
                <img src={img} alt={name} />
            </div>
            <h4 style={{ marginBottom: '10px' }}>{name}</h4>
            <span className="team-border-bottom">{position}</span>
            <a 
                href={linkedinLink} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="team-linkedin"
               
            >
                LinkedIn
            </a>
        </div>
    );
};



const TeamStyleThree = () => {
    return (
        <div className="team-area">
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    {TeamStyleThreeData.map((data) => (
                        <div key={data.id} className="col-xl-4 col-lg-4 col-md-6 d-flex justify-content-center">
                            <TeamStyleThreeItem
                                img={data.img}
                                name={data.name}
                                position={data.position}
                                linkedinLink={data.linkedinLink}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TeamStyleThree;
