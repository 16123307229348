import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomOne from '../common/header/HeaderBottomOne';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/partners-banner.JPG';
import ServiceStyleTwo from '../components/services/ServiceStyleTwo';
import WhyChoseUsTwo from '../components/why-choose-us/WhyChoseUsTwo';
import Subscribe from '../components/subscribe/Subscribe';
import FooterOne from '../common/footer/FooterOne';

const ServiceTwo = () => {
    return (
        <>   <HeaderBottomOne />

            <Breadcrumb breadcrumbBg={breadcrumbBg} heading="Partners" currentPage="Partners" />
            <ServiceStyleTwo />
        
            <FooterOne />
        </>
    )
}

export default ServiceTwo;