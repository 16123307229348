import React from 'react';
import OLACirclesLogo from '../../assets/images/about/icon-logo.png';

const MaterialRecoveryAbout = () => {
    return (
        <>
            <div className="what-is-ola-area height-470 white-bg-2 pt-20 pb-160 ">
                <div className="container ">
                    <div className="row">
                       
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="what-is-ola-text pt-30  ">
                                <h1>
                                    <span className="ola-title">What is </span>
                                    <span className="ola-title-bold">
                                        OLA<span className="ola-title">Circles ?</span>
                                    </span>
                                    <br />
                                </h1>
                                <p className="ola-description">
                                OLACircles is a program under the outreach leadership academy that focuses on mainstreaming the concepts of circularity and sustainable practices.                                </p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="about-img-style-3 ">
                                <img src={OLACirclesLogo} alt="About" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MaterialRecoveryAbout;
