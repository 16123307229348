import React from 'react';
import {Link} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import GreenEducation from '../../assets/images/how-it-works/green-edu.png';
import Business from '../../assets/images/how-it-works/business.JPG';

const HowItWorkOne = () => {
    return (
        <>
            <div className="how-it-works theme-bg-5 pt-40 pb-40">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title text-center mb-50">
                                <h2 style={{color:'#4c9152'}}>Milestones</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <Tab.Container defaultActiveKey="first">
                     
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                <div className="row-milestone">
                                        <div className="milestone-first">
                                            <div className="works-content-wrapper">
                                                <div className="works-icon">
                                                    <img src={GreenEducation} alt="Green Education"/>
                                                </div>
                                                <h3>Green Education</h3>
                                                <div className="works-img">
                                                    <span style={{color:'#4c9152'}}>
                                                        Starting 2024 green education within OLA started, with the creation of nine green ambassadors Clubs in nine schools from the School Network of Saida and Neighboring, intending to align the educational process with sustainable environmental development standards. Sixty-two students participated in capacity-building training over three weeks on Climate Change and Circular Economy and graduated as green ambassadors to advocate for eco-friendly practices and raise awareness within their community or organization.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="milestone-second">
                                            <div className="works-content-wrapper">
                                                <div className="works-icon">
                                                    <img src={Business} alt="Business Empowerment"/>
                                                </div>
                                                <h3>Business Empowerment</h3>
                                                <div className="works-img">
                                                    <span style={{color:'#4c9152'}}>
                                                        By integrating circular economy principles, businesses can not only reduce their environmental impact but also uncover new opportunities for growth, innovation, and customer engagement, ultimately building a more resilient and sustainable business. For the reason to enhance the sustainability of small and medium businesses(SMEs) in Saida area, an awareness session was done in OLA for twenty-eight SME owners to learn more about the circularity loop and how they could take advantage of and apply it in their businesses.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HowItWorkOne;
