import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FaBullhorn, FaHandsHelping, FaChartLine, FaLightbulb, FaChartPie, FaBook } from 'react-icons/fa';

const ServiceOneData = [
    {
        id: uuidv4(),
        heading: 'Raising Awareness',
        description: 'Raise awareness about circularity practices, between individuals and organizations to contribute to a more sustainable future.',
        icon: <FaBullhorn />,
        serviceLink: '/',
    },
    {
        id: uuidv4(),
        heading: 'Mobilizing & Innovating',
        description: 'Mobilize a network of local government and corporate partners to craft and implement Circularity within the city.',
        icon: <FaHandsHelping />,
        serviceLink: '/',
    },
    {
        id: uuidv4(),
        heading: 'Tracking & Earning',
        description: 'Marketplace feature where businesses and individuals can check their account credit from selling specific goods.',
        icon: <FaChartLine />,
        serviceLink: '/',
    }, 
    {
        id: uuidv4(),
        heading: 'Inspiring & Transforming',
        description: 'By fostering a connected and informed community, we aim to inspire collective action towards reducing waste, conserving resources, and transforming our city into a model of circularity.',
        icon: <FaLightbulb />,
        serviceLink: '/',
    },
    {
        id: uuidv4(),
        heading: 'Analyzing & Optimizing',
        description: 'The platform offers data analytics and insights, to help city planners and policymakers identify trends, monitor progress, and optimize resource flows within the city.',
        icon: <FaChartPie />,
        serviceLink: '/',
    },
    {
        id: uuidv4(),
        heading: 'Learning & Succeeding',
        description: 'A comprehensive library of resources, and videos, to provide valuable information on circular economy practices and success stories.',
        icon: <FaBook />,
        serviceLink: '/',
    }
];

const ServiceOneItem = ({ icon, heading, description, serviceLink }) => {
    return (
        <div className="service-column col-xl-4 col-lg-4 col-md-6 mb-4">
            <div className="single-service text-center h-100 d-flex flex-column">
                <div className="service-icon mb-3">
                    {icon}
                </div>
                <h3 className="service-heading">{heading}</h3>
                <p className="service-description flex-grow-1">{description}</p>
            </div>
        </div>
    );
};

const ServiceStyleOne = () => {
    return (
        <div className="service-area">
            <div className="container">
                <div className="row-services">
                    <div className="col-xl-12">
                        <div className="about-text-wrapper">
                            <h1>
                                <span className="ola-title">Our Contributions</span><br/>
                            </h1>
                        </div>
                    </div>
                </div>

                {/* First row of services */}
                <div className="row-services">
                    {ServiceOneData.map((data, index) => (
                        <ServiceOneItem
                            key={data.id}
                            icon={data.icon}
                            heading={data.heading}
                            description={data.description}
                            serviceLink={data.serviceLink}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ServiceStyleOne;
