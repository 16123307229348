import React from 'react';
import MaterialRecoveryAboutImg1 from '../../assets/images/about/history.jpg';
import AboutVideoBlock from './AboutVideoBlock';

const AboutBlock = () => {
    return (
        <>
            <div className="history-area white-bg-2 height-470 pt-30">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="about-img-style-2">
                                <img src={MaterialRecoveryAboutImg1} className="about-img" alt="About History" />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="history-text">
                                <div className="section-title mb-40">
                                    <h2>History</h2><br />
                                    <span>The Outreach & Leadership Academy (OLA) is housed in the 18th-century Dar Ali Hammoud Palace in Saida’s Historic District, later turned into the Aisha Heritage School. The school site, abandoned since the 1950s and used as a hub of drug trafficking during the civil war, was restored by the Hariri Foundation for Sustainable Human Development to become an educational hub that contributes to the community’s social transformation.</span>
                                    <span> In 2015 as a beacon of civic engagement and social responsibility upon the strategic partnership agreement between the Hariri Foundation for Sustainable Human Development and the Lebanese American University. </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AboutVideoBlock />
        </>
    );
}

export default AboutBlock;
