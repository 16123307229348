import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faRocket, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

const AboutExperienceData = [
  {
    id: uuidv4(),
    heading: 'Vision',
    description:
      'Envisions a future where education and action converge to create resilient, eco-friendly societies committed to the preservation and stewardship of our planet for future generations to cultivate a generation of environmentally conscious leaders.',
    icon: faEye,
  },
  {
    id: uuidv4(),
    heading: 'Mission',
    description: `The Outreach & Leadership Academy (OLA) is an educational and cultural center dedicated to educating, empowering, and engaging local communities, especially in South Lebanon. OLA provides free capacity-building training programs, civic engagement, and touristic activities under the mission of promoting civic engagement, social awareness, leadership, and citizenship through education.
        OLA programs have significantly contributed to poverty alleviation in Saida's historic district by equipping locals with employability skills, creating job opportunities, and challenging the district's insecurity stereotype. To date, OLA has hosted over 380 programs and activities, impacting more than 7,500 participants from diverse backgrounds. These include the Model Council of Ministers, Model League of Arab States, Model European Union, Model United Nations, Lebanon 2020: The State of Knowledge, and various training programs on employability and soft skills.`,
    icon: faRocket,
  },
];

const AboutExperienceItem = ({ heading, icon }) => {
  return (
    <div
      className="single-experience mb-3"
      style={{ marginBottom: '12px', padding:'0', display: 'flex', alignItems: 'center' }} // Reduced bottom margin
    >
      <div style={{ marginRight: '10px' }}> {/* Reduced margin */}
        <FontAwesomeIcon icon={icon} style={{ fontSize: '22px', color: '#4c9152' }} />
      </div>
      <div style={{ color: '#5391b7' }}>
        <h3>{heading}</h3>
      </div>
    </div>
  );
};

const AboutExperience = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="experience-area pt-20 pb-10" style={{ backgroundColor: '#5391b7' }}>
      <div className="container">
        <div className="row">
          {AboutExperienceData.map((data) => (
            <Accordion
              key={data.id}
              expanded={expanded === data.id}
              onChange={handleChange(data.id)}
              className="custom-accordion"
            >
              <AccordionSummary
                aria-controls={`panel${data.id}-content`}
                id={`panel${data.id}-header`}
                style={{
                  position: 'relative',
                  borderRadius: '16px',  
                  padding: '4px 12px',   // Further reduced padding
                }}
              >
                <AboutExperienceItem
                  heading={data.heading}
                  icon={data.icon}
                />
                <FontAwesomeIcon
                  icon={faChevronDown}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    transition: 'transform 0.3s ease',
                    color: '#4c9152',
                    fontSize: '24px',
                    transform: expanded === data.id ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />
              </AccordionSummary>
              <AccordionDetails style={{ borderRadius: '0 0 16px 16px', padding: '8px 12px' }}> {/* Reduced padding */}
                <p>{data.description}</p>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutExperience;
