import React from 'react'; 
import MaterialRecoveryProjectImg1 from '../../assets/images/about/car.png';
import InteractiveMap from '../home1/InteractiveMap';

const AboutBqostaTwo = () => {
    return (
        <>
            <div className="about-bqosta2 white-bg-2 pb-20">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            
                            <div className="section-title text-center mb-70">
                                <h2>Existing Operational <br />
                                Processes and Capacities</h2><br/>
                                <span>The existing operational processes and capacities of the Bqosta Sorting Facility are integral to its waste management capabilities. Based on the size of the available 6.5m-long conveyor belt and the floor area of the enclosed structure, the facility is presently equipped to process approximately 7 tons of inorganic material per day, contingent upon normal operational conditions and the availability of requisite human capital. The available storage area, post-deductions for operational workspaces, stands at approximately 256 m2. However, it is essential to note that these operational capacities are subject to optimal conditions. Our calculations, based on data received, estimate a daily waste generation of 3 tons in the target area.  </span>
                            </div>
                        </div>
                    </div>
                    <div className="row portfolio-active">
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className='bus-img' style={{marginBottom:'45%'}}>
                        <img src={MaterialRecoveryProjectImg1}/>
                       
                        </div>
                       
                        </div>
                       
                   </div>
                
                </div>
            </div>
        </>
    );
}

export default AboutBqostaTwo;
