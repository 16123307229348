import React from 'react';

const OlaResources = () => {
    return (
        <>
            <div
                className="goal-ola-container"
                style={{
                    display: 'flex',  // Use flexbox
                    alignItems: 'center',  // Center vertically
                    justifyContent: 'center',  // Center horizontally
                    position: 'relative',  // To maintain background positioning
                    height:'50vh',
                }}
            >
                <div
                    className="goal-ola-background"
                    style={{
                        position: 'absolute',  // Background stays behind the content
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: -1, 
                    }}
                ></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-12">
                            <div
                                className="ola-text pt-30"
                                style={{
                                    textAlign: 'center',  // Center text horizontally
                                    backgroundColor: 'white',  // Optional: Background color for text area
                                    padding: '20px',  // Padding for text area
                                    borderRadius: '70px',  // Rounded corners
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',  // Optional: Shadow for text area
                                }}
                            >
                                <p className="goal-ola-description" >
                                    There are currently no reports available, but stay tuned for upcoming releases. <br /> Get ready to access the latest updates and insights soon!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OlaResources;
