import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const PointOfCollectionItem = ({ heading, description, icon, link, style }) => {
    return (
        <div
            className={`col-xl-3 col-lg-3 col-md-6 d-flex flex-column align-items-center mb-4 point-of-collection-item-container`}
            style={style}
        >
            <h3 className="point-of-collection-heading">{heading}</h3>
            <div className="point-of-collection">
                <div className="point-of-collection-content b-color-6">
                    <p>{description}</p>
                </div>
            </div>
        </div>
    );
};

const PointOfCollection = () => {
    return (
        <div className="point-of-collection-section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-12">
                        <div
                            className="point-of-collection-title"
                            style={{ textAlign: 'left' }} // Align text to the left
                        >
                            <h2
                                className="point-of-collection-heading-title"
                                style={{ textAlign: 'left', fontWeight:'100', fontSize:'50px' }} // Align heading to the left
                            >
                                What is Circularity ?
                            </h2>
                            <p
                                style={{ textAlign: 'left', lineHeight:'2' }} // Align paragraph text to the left
                            >
                                Circularity refers to the concept of keeping resources in use for as long as possible, extracting maximum value from them before recovering and regenerating products and materials at the end of their lifecycle.
                                <br />  
                                Source: Ellen MacArthur Foundation.
                                <br />
                                "What is a Circular Economy?" Ellen MacArthur Foundation.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PointOfCollection;
