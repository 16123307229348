import React from 'react';
import { Link } from 'react-router-dom';
import LogoImg1 from "../../assets/images/icon-logo-cropped.png";  
import LogoImg2 from "../../assets/images/text-logo-cropped.png"; 

const Logo = ({ showIconLogo, style }) => {
    return (
        <div className="logo">
            <Link to="/">
                <img style={style} src={showIconLogo ? LogoImg1 : LogoImg2} alt="Logo" />
            </Link>
        </div>
    );
}



export default Logo;




