import React from 'react';
import HeaderBottomOne from '../common/header/HeaderBottomOne';
import FooterOne from '../common/footer/FooterOne';
import AboutExperience from '../components/about/AboutExperience';
import AboutBlock from '../components/about/AboutBlock';
import HowItWorkOne from '../components/how-it-work/HowItWorkOne';


const About = () => {
    return (
        <>
            <HeaderBottomOne />
               <AboutBlock />
            <AboutExperience />
    
            <HowItWorkOne />
            <FooterOne />
            
        </>
    )
}

export default About;

