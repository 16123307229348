import React from 'react';

import HeaderBottomOne from '../common/header/HeaderBottomOne';
import FooterOne from '../common/footer/FooterOne';
import BlogContent from '../components/blog-details/BlogContent';


const BlogDetails = () => {
    return (
        <>
    
            <HeaderBottomOne />
       
            <div className="blog-details-area">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 pr-50">
                            <BlogContent />
                      
                        </div>
                     
                    </div>
                </div>
            </div>
            <FooterOne />
        </>
    )
}

export default BlogDetails;