import React from 'react';
import MaterialRecoveryAboutImg1 from '../../assets/images/about/defcircular.jpg';
import OlaLogoFull from '../../assets/images/new-logo.png';


const MaterialRecoveryAbout = () => {
    return (
        <>
            <div className="about-area height-470 white-bg-2 pt-20 pb-160">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="about-img-style-1">
                                <img src={MaterialRecoveryAboutImg1} alt="About" />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="about-text-wrapper pt-30">
                            <img src={OlaLogoFull} alt="About" />

                                <h1>

                                    <span className="ola-title">What is </span>
                                    <span className="ola-title-bold">
                                        OLA<span className="ola-title">Circles ?</span>
                                    </span>
                                    <br />
                                </h1>
                                <p className="ola-description">
                                    A platform highlighting initiatives in circularity serving as a dynamic space for fostering sustainable practices and encouraging collaborative efforts toward a more resource-efficient future. It is a bridge between various stakeholders and citizens to showcase innovative projects, share best practices, and implement circular principles effectively.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MaterialRecoveryAbout;
