import React from 'react';
import HeaderBottomOne from '../common/header/HeaderBottomOne';
import FooterOne from '../common/footer/FooterOne';

import AboutFacilitySub from '../components/about/AboutFacilitySub';
import AcceptedRecyclables from '../components/about/AcceptedRecyclables';
import PointOfCollection from '../components/about/PointOfCollection';



const MaterialRecovery = () => {
    return (
        <>
            <HeaderBottomOne />
          
            <AboutFacilitySub/>
            <AcceptedRecyclables/>
            <PointOfCollection/>

            <FooterOne />

        </>
    )
}

export default MaterialRecovery;