import React from 'react';
import Bqosta from '../../assets/images/about/bqosta.png';

const AboutBqostaOne = () => {
    return (
        <>
            <div className="about-area white-bg-2 pt-30 pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="about-img-style-1">
                                <img src={Bqosta} alt="" />
                              
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="about-text-wrapper pt-30">
                                <div className="section-title mb-40">
                                    
                                    <h2 className="mb-30">Bqosta Facility</h2>
                                    <p>The BMRF plays a critical role in waste management by sorting, baling, and selling inorganic waste to local recyclers. As an integral part of Bqosta's environmental sustainability efforts, the facility's strategic location and well-designed infrastructure contribute to the effective processing of waste materials. The municipality's commitment to renewing the rental agreement for an extended period underscores the long-term vision for the facility's continued service to the community.    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutBqostaOne;