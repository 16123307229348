import React from 'react';

import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/experts.JPG';
import OlaTeam from '../components/team/OlaTeam';

import FooterOne from '../common/footer/FooterOne';
import HeaderBottomOne from '../common/header/HeaderBottomOne';


const Team = () => {
    return (
        <>
            <HeaderBottomOne />
            <Breadcrumb breadcrumbBg={breadcrumbBg} heading="Our Team" description="Team of circularity in lebanon " currentPage="Our Team" />
            <OlaTeam />
            <FooterOne />
            
        </>
    )
}

export default Team;