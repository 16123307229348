import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import busIconUrl from '../../assets/images/bus-icon.png';

const InteractiveMap2 = () => {
    const locations = [
        {
            id: 1,
            lat: 33.567905382752, // Updated latitude
            lng: 35.41697345767013, // Updated longitude
            description: 'Bqosta Recovery Material Facility', 
            streetViewUrl: 'https://www.google.com/maps/@33.567905382752,35.41697345767013,3a,75y,90t/data=!3m6!1e1!3m4!1sh--M66y5HkVKVKT8M6axXA!2e0!7i16384!8i8192'
        },
        
     
        {
            id: 13,  // New unique ID for the bus
            lat: 33.57715185568374,  // Latitude for the bus location
            lng: 35.39696354053379,  // Longitude for the bus location
            description: 'Bus Location',
            streetViewUrl: 'https://maps.app.goo.gl/W3gkbjdrpo7FMZvu8?g_st=com.google.maps.preview.copy'
        }
        
        
    ];

   // Custom Pinpoint Icon Styles
   const redIcon = new L.DivIcon({
    className: 'custom-pin-icon-red',  // Class for custom styling
    html: `
        <div style="
            background-color: red;  /* Red color */
            width: 15px;
            height: 15px;
            border-radius: 50%;  /* Full circle to retain the pin style */
            transform: rotate(0deg);
            position: relative;
            left: -7.5px;
            top: -7.5px;
        ">
            <div style="
                width: 5px;
                height: 5px;
                background: white;
                border-radius: 50%;
                position: absolute;
                top: 5px;
                left: 5px;
            "></div>
        </div>
    `,
    iconSize: [15, 15],
    iconAnchor: [7.5, 7.5]
});

const greenIcon = new L.DivIcon({
    className: 'custom-pin-icon-green',  // Class for custom styling
    html: `
        <div style="
            background-color: green;  /* Green color */
            width: 15px;
            height: 15px;
            border-radius: 50%;  /* Full circle to retain the pin style */
            transform: rotate(0deg);
            position: relative;
            left: -7.5px;
            top: -7.5px;
        ">
            <div style="
                width: 5px;
                height: 5px;
                background: white;
                border-radius: 50%;
                position: absolute;
                top: 5px;
                left: 5px;
            "></div>
        </div>
    `,
    iconSize: [15, 15],
    iconAnchor: [7.5, 7.5]
});

// Bus Icon
const busIcon = new L.Icon({
    iconUrl: busIconUrl,
    iconSize: [32, 32],  // Adjust size as needed
    iconAnchor: [16, 32],  // Adjust anchor as needed
});

return (
    <div className="interactive-map-container">
        <div className="book-appointment">
            <div className="container">
                <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 text-col">
                        <div className="book-quote-form">
                            <div className="section-title">
                                <h2> Venues of Circularity</h2>
                                <span>Navigate the Map to Learn About Our Projects</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 map-col">
                    <MapContainer 
                            center={[33.859, 35.556]} // Center of Lebanon
                            zoom={9} // Adjust zoom level to fit Lebanon
                            style={{ height: "500px", width: "550px", border:'3px solid #5391b7', borderRadius:'25px' }}
                            attributionControl={false}
                        >

                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {locations.map(location => (
                                  <Marker 
                                  key={location.id}
                                  position={[location.lat, location.lng]}
                                  icon={location.id === 13 ? busIcon : (location.id <= 3 ? redIcon : greenIcon)}
                              >
                                  <Popup>
                                      <div>{location.description}</div>
                                      <a href={location.streetViewUrl} target="_blank" rel="noopener noreferrer">View in Street View</a>
                                  </Popup>
                              </Marker>
                        
                            ))}
                        </MapContainer>
                    </div>

                

                    
                </div>
            </div>
        </div>
    </div>
);
}

export default InteractiveMap2;