import React from 'react';

import { v4 as uuidv4 } from 'uuid';
import TeamStyleThreeImg1 from '../../assets/images/team/jihad.png';
import TeamStyleThreeImg2 from '../../assets/images/team/marwa.jpg';

const TeamStyleThreeData = [
    {
        id: uuidv4(),
        img: TeamStyleThreeImg1,
        name: 'Jihad Akoum',
        position: 'Bqosta Fcaility Manager',
        linkedinLink: 'https://www.linkedin.com/in/jihad-akoum-4a7621183?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BEg3KB4kUSwi2y5W%2FAEq1pQ%3D%3D',
    },

];
const TeamStyleThreeItem = ({ img, name, position, linkedinLink }) => {
    return (
        <div className="single-team-style-1 text-center">
            <div className="single-team-style-1-img">
                <img src={img} alt={name} />
            </div>
            <h4 style={{ marginBottom: '10px' }}>{name}</h4>
            <span className="team-border-bottom">{position}</span>
            <a 
                href={linkedinLink} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="team-linkedin"
               
            >
                LinkedIn
            </a>
        </div>
    );
};



const TeamStyleThree = () => {
    return (
        <div className="team-area">
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    {TeamStyleThreeData.map((data) => (
                        <div key={data.id} className="col-xl-4 col-lg-4 col-md-6 d-flex justify-content-center">
                            <TeamStyleThreeItem
                                img={data.img}
                                name={data.name}
                                position={data.position}
                                linkedinLink={data.linkedinLink}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TeamStyleThree;
