import React from 'react';
import { Link } from 'react-router-dom';
import FooterBG from '../../assets/images/grass.jpg';
import Logo from '../../assets/images/hf.png';
import { FaPhoneAlt, FaFacebookF, FaTwitter, FaInstagram, FaPinterestP, FaAngleDoubleRight, FaEnvelope } from 'react-icons/fa';
import { FaLocationDot } from "react-icons/fa6";

const FooterOne = () => {
    return (
        <>
            <footer>
                <div className="footer-top bg-property theme-bg-2" style={{ backgroundImage: `url(${FooterBG})` }}>
                    <div className="footer-container">
                        <div className="row">
                            {/* Contact Us Section */}
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 footer-col">
                                <div className="footer-title">
                                    <h4>Contact Us</h4>
                                </div>
                                <div className="footer-info">
                                    <Link to="/"><FaPhoneAlt /> +961 3 011 793</Link>
                                </div>
                                <div className="footer-info">
                                    {/* Properly align the envelope icon beside the email address */}
                                    <Link to="/"><FaEnvelope /> info@olacircles.com</Link>
                                </div>
                                <div className="footer-info">
                                    <Link to="/">
                                        <FaLocationDot /> Solidere Building 1301, 5th Floor, <br /> Riad Solh Road, Downtown Beirut, Lebanon<br/>
                                        Hariri Foundation Head Offices 
                                    </Link>

                                </div>
                            </div>

                            {/* Quick Links Section */}
                            <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12 quick-links footer-col">
                                <div className="footer-title">
                                    <h4>Quick Links</h4>
                                </div>
                                <div className="footer-menu">
                                    <ul>
                                        <li><Link to="/about-olacircles">About OLACircles</Link></li>
                                        <li><Link to="/#our-community">Our Community</Link></li>
                                        <li><Link to="/success-stories">Success Stories</Link></li>
                                    </ul>
                                </div>
                            </div>

                            {/* Sectors Section */}
                            <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12 sectors-logo-wrapper footer-col">
                                <div className="footer-title">
                                    <h4 style={{ whiteSpace: 'nowrap' }}>Community Sectors</h4>
                                </div>
                                <div className="footer-menu">
                                    <ul>
                                        <li><Link to="/material-recovery">Recovery Material</Link></li>
                                        <li style={{color:'white'}}>Water</li>
                                        <li style={{color:'white'}}>Textile</li>
                                    </ul>
                                </div>
                            </div>

                            {/* Logo Section */}
                            <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12 sectors-logo-wrapper footer-col">
                                <img style={{width:'250px'}}src={Logo} alt="Logo" className="footer-logo" />
                            </div>

                          
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default FooterOne;