import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import CNRS from '../../assets/images/service/cnrs.png';
import MunicipalityBqosta from '../../assets/images/service/municipality-of-bqosta.png';
import MunicipalitySaida from '../../assets/images/service/Saida-Municiplaity.png';
import MOE from '../../assets/images/service/moe.png';
import UNEP from '../../assets/images/service/unep.png';
import SNS from '../../assets/images/service/sns.png';

const ServiceTwoData = [
    {
        id: uuidv4(),
        heading: 'CNRS  ',
        description: 'There are many variations of passages the majority have suffered alteration form',
        img: CNRS,
    },
    {
        id: uuidv4(),
        heading: ' Municipality  of Bqosta ',
        description: 'There are many variations of passages the majority have suffered alteration form',
        img: MunicipalityBqosta,
    },
    {
        id: uuidv4(),
        heading: 'Municipality of Saida  ',
        description: 'There are many variations of passages the majority have suffered alteration form',
        img: MunicipalitySaida,
    },
    {
        id: uuidv4(),
        heading: 'Ministry of Environment ',
        description: 'There are many variations of passages the majority have suffered alteration form',
        img: MOE,

    },
    {
        id: uuidv4(),
        heading: 'UNEP ',
        description: 'There are many variations of passages the majority have suffered alteration form',
        img: UNEP,

    },
    {
        id: uuidv4(),
        heading: 'SNS  ',
        description: 'There are many variations of passages the majority have suffered alteration form',
        img: SNS,
    },

];

const ServiceTwoItem = ({ img, heading, description, serviceLink }) => {
    return (
        <>
            <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="single-service2 text-center" >
                    <div className="service-icon ml-5">
                        <img src={img} alt="Service Icon" />
                    </div>
                    <h3>{heading}</h3>
                  
                </div>
            </div>
        </>
    )
}

const ServiceStyleTwo = () => {
    return (
        <>
        <div className="service-area" style={{ backgroundColor: '#ffffff', paddingTop: '30px', paddingBottom: '30px'}}>
        <div className="container">
                   
                    <div className="row">
                        {ServiceTwoData.map((data) => (
                            <ServiceTwoItem
                                key={data.id}
                                img={data.img}
                                heading={data.heading}
                                description={data.description}
                                serviceLink={data.serviceLink}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServiceStyleTwo;