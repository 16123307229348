import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import busIconUrl from '../../assets/images/bus-icon.png';


const InteractiveMap = () => {
    const locations = [
        {
            id: 1,
            lat: 33.567905382752, // Updated latitude
            lng: 35.41697345767013, // Updated longitude
            description: 'Bqosta Recovery Material Facility', 
            streetViewUrl: 'https://www.google.com/maps/@33.567905382752,35.41697345767013,3a,75y,90t/data=!3m6!1e1!3m4!1sh--M66y5HkVKVKT8M6axXA!2e0!7i16384!8i8192'
        },
        {
            id: 2,
            lat: 33.564381, // Updated latitude
            lng: 35.3699369, // Updated longitude
            description: 'Anamilouna – Women Empowerment Center', 
            streetViewUrl: 'https://www.google.com/maps/@33.5630936,35.3728551,3a,75y,90t/data=!3m6!1e1!3m4!1s0x151ef10f74724ac3%3A0xccdcace98a19c357!2sAnamilouna%20%E2%80%93%20Women%20Empowerment%20Center!2e0!7i1024!8i768'
        },
        {
            
                id: 3,
                lat: 33.564192473283086,  // Updated latitude
                lng: 35.37065874870613,  // Updated longitude
                description: 'OLA',
                streetViewUrl: 'https://www.google.com/maps/@33.564192473283086,35.37065874870613,3a,75y,90t/data=!3m6!1e1!3m4!1sfakeId!2e0!7i13312!8i6656'
        
            
        },
        {
            id: 4,
            lat: 33.5817912, // Updated latitude
            lng: 35.3886312, // Updated longitude
            description: 'Rafic Hariri High School, https://rhhs.edu.lb',
            streetViewUrl: 'https://www.google.com/maps/place/Rafic+Hariri+High+School/@33.5813488,35.3885668,427m/data=!3m1!1e3!4m6!3m5!1s0x151efaac9c5cdf6f:0x73a55ec5ecdc2fc2!8m2!3d33.5817912!4d35.3886312!16s%2Fg%2F1hjgjjst2?entry=ttu&g_ep=EgoyMDI0MDkwMy4wIKXMDSoASAFQAw%3D%3D'  // Updated Street View link
        },
        {
            id: 5,
            lat: 33.5758334,
            lng: 35.4021861,
            description: 'Houssam Eddine Hariri High School – The Makassed Islamic Philanthropic Society in Saida, https://mak-hhhs.edu.lb/',
            streetViewUrl: 'https://www.google.com/maps/place/Houssam+Eddine+Hariri+High+School/@33.5758334,35.4021861,17z/data=!4m6!3m5!1s0x151ee559de1b877b:0x7f6fde6df93001d!8m2!3d33.576267!4d35.401816!16s%2Fg%2F11z9l6r6c?entry=ttu&g_ep=EgoyMDI0MDkwMy4wIKXMDSoASAFQAw%3D%3D'
        },
        {
            id: 6,
            lat: 33.5528668,
            lng: 35.3698313,
            description: 'Hajj Bahaa Hariri High School',
            streetViewUrl: 'https://www.google.com/maps/place/Hajj+Bahaa+Hariri+High+School/@33.5528668,35.3698313,17z/data=!3m1!4b1!4m6!3m5!1s0x151ef039ddad28ed:0x5affd200e299959a!8m2!3d33.5528624!4d35.3724116!16s%2Fg%2F1td450cr?entry=ttu&g_ep=EgoyMDI0MDkwMy4wIKXMDSoASAFQAw%3D%3D'
        },
        {
            id: 7,
            lat: 33.56306,
            lng: 35.3721566,
            description: 'Angelic Saliba Public School',
            streetViewUrl: 'https://www.google.com/maps/place/Angelic+Saliba+Public+School/@33.56306,35.3721566,17z/data=!3m1!4b1!4m6!3m5!1s0x151ef1215c6c324b:0xd8c7438a1f9f2a7a!8m2!3d33.5630556!4d35.3747369!16s%2Fg%2F11sfkq_7rr?entry=ttu&g_ep=EgoyMDI0MDkwMy4wIKXMDSoASAFQAw%3D%3D'
        },
        {
            id: 8,
            lat: 33.5620394,
            lng: 35.3751707,
            description: 'Dr. Hikmat Sabbagh Public High School for Girls',
            streetViewUrl: 'https://www.google.com/maps/dir/Dr.+Hikmat+Sabbagh+Public+High+School+for+Girls/H96G%2BR37+Dr.+Hikmat+Sabbagh+Public+High+School+for+Girls,+Sidon/@33.5569186,35.3913918,15z/data=!4m13!4m12!1m5!1m1!1s0x151ef016191338ad:0x89acd0647c474d2!2m2!1d35.3751707!2d33.5620394!1m5!1m1!1s0x151ef016191338ad:0x89acd0647c474d2!2m2!1d35.3751707!2d33.5620394?entry=ttu&g_ep=EgoyMDI0MDkwMy4wIKXMDSoASAFQAw%3D%3D'
        },
        {
            id: 9,
            lat: 33.56812418385907,  // Updated latitude
            lng: 35.37826988963665,  // Updated longitude
            description: 'Lebanese Kuwaiti School, https://shorturl.at/Gd2NC',
            streetViewUrl: 'https://www.google.com/maps/@33.56812418385907,35.37826988963665,3a,75y,90t/data=!3m6!1e1!3m4!1sfakeId!2e0!7i13312!8i6656'
        }
,        
        {
            id: 10,
            lat: 33.5682756,
            lng: 35.3766149,
            description: 'Dr. Nazih Bizri High School',
            streetViewUrl: 'https://www.google.com/maps/place/Dr.+Nazih+Bizri+High+School/@33.5682756,35.3766149,17z/data=!3m1!4b1!4m6!3m5!1s0x151ef01079fff9df:0xab91a2938e15bd72!8m2!3d33.5682712!4d35.3791952!16s%2Fg%2F1262vv7kr?entry=ttu&g_ep=EgoyMDI0MDkwMy4wIKXMDSoASAFQAw%3D%3D'
        },
        {
            id: 11,
            lat: 33.5503473,
            lng: 35.3739851,
            description: 'Oman Model School',
            streetViewUrl: 'https://www.google.com/maps/dir//%D8%A7%D9%84%D9%85%D8%AF%D8%B1%D8%B3%D8%A9+%D8%A7%D9%84%D8%B9%D9%85%D8%A7%D9%86%D9%8A%D8%A9+%D8%A7%D9%84%D9%86%D9%85%D9%88%D8%B2%D8%AC%D9%8A%D8%A9+%D8%A7%D9%84%D8%B1%D8%B3%D9%85%D9%8A%D8%A9,+Sidon%E2%80%AD/@33.5240198,35.5346601,10z/data=!4m8!4m7!1m0!1m5!1m1!1s0x151ef0372d4353f7:0x547c01e2402d48f9!2m2!1d35.3739851!2d33.5503473?entry=ttu&g_ep=EgoyMDI0MDkwMy4wIKXMDSoASAFQAw%3D%3D'
        },
        {
            id: 12,
            lat: 33.54881955647551,  // Updated latitude
            lng: 35.38131177368487,  // Updated longitude
            description: 'Mostafa Zaatari High School',
            streetViewUrl: 'https://www.google.com/maps/place/Mostafa+Zaatari+High+School/@33.54881955647551,35.38131177368487,17z/data=!3m1!4b1!4m6!3m5!1s0x151ef1162e5c87d5:0x7b0744a3f22ed3d8!8m2!3d33.5488196!4d35.3813118?entry=ttu&g_ep=EgoyMDI0MDkwMy4wIKXMDSoASAFQAw%3D%3D'
        },
        {
            id: 13,  // New unique ID for the bus
            lat: 33.57715185568374,  // Latitude for the bus location
            lng: 35.39696354053379,  // Longitude for the bus location
            description: 'Bus Location',
            streetViewUrl: 'https://maps.app.goo.gl/W3gkbjdrpo7FMZvu8?g_st=com.google.maps.preview.copy'
        }
        
        
    ];

   // Custom Pinpoint Icon Styles
   const redIcon = new L.DivIcon({
    className: 'custom-pin-icon-red',  // Class for custom styling
    html: `
        <div style="
            background-color: red;  /* Red color */
            width: 15px;
            height: 15px;
            border-radius: 50%;  /* Full circle to retain the pin style */
            transform: rotate(0deg);
            position: relative;
            left: -7.5px;
            top: -7.5px;
        ">
            <div style="
                width: 5px;
                height: 5px;
                background: white;
                border-radius: 50%;
                position: absolute;
                top: 5px;
                left: 5px;
            "></div>
        </div>
    `,
    iconSize: [15, 15],
    iconAnchor: [7.5, 7.5]
});

const greenIcon = new L.DivIcon({
    className: 'custom-pin-icon-green',  // Class for custom styling
    html: `
        <div style="
            background-color: green;  /* Green color */
            width: 15px;
            height: 15px;
            border-radius: 50%;  /* Full circle to retain the pin style */
            transform: rotate(0deg);
            position: relative;
            left: -7.5px;
            top: -7.5px;
        ">
            <div style="
                width: 5px;
                height: 5px;
                background: white;
                border-radius: 50%;
                position: absolute;
                top: 5px;
                left: 5px;
            "></div>
        </div>
    `,
    iconSize: [15, 15],
    iconAnchor: [7.5, 7.5]
});

// Bus Icon
const busIcon = new L.Icon({
    iconUrl: busIconUrl,
    iconSize: [32, 32],  // Adjust size as needed
    iconAnchor: [16, 32],  // Adjust anchor as needed
});

return (
    <div className="interactive-map-container">
        <div className="book-appointment">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 text-col">
                        <div className="book-quote-form">
                            <div className="section-title">
                                <h2>Venues of Circularity</h2>
                                <span>Navigate the Map to Learn About Our Projects</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 map-col">
                        <MapContainer 
                            center={[33.859, 35.556]} // Center of Lebanon
                            zoom={9} // Adjust zoom level to fit Lebanon
                            style={{ height: "500px", width: "550px", border:'3px solid #5391b7', borderRadius:'25px' }}
                            attributionControl={false}
                        >
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {locations.map(location => (
                                <Marker 
                                    key={location.id}
                                    position={[location.lat, location.lng]}
                                    icon={location.id === 13 ? busIcon : (location.id <= 3 ? redIcon : greenIcon)}
                                >
                                    <Popup>
                                        <div>{location.description}</div>
                                        <a href={location.streetViewUrl} target="_blank" rel="noopener noreferrer">View in Street View</a>
                                    </Popup>
                                </Marker>
                            ))}
                        </MapContainer>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
}

export default InteractiveMap;