import React from 'react';

import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/breadcrumb/experts.JPG';
import TeamStyleThree from '../components/team/TeamStyleThree';

import FooterOne from '../common/footer/FooterOne';
import HeaderBottomOne from '../common/header/HeaderBottomOne';


const Experts = () => {
    return (
        <>
            <HeaderBottomOne />
            <Breadcrumb breadcrumbBg={breadcrumbBg} heading="Our Experts" description="Experts in circularity in lebanon " currentPage="Our Experts" />
            <TeamStyleThree />
            <FooterOne />
            
        </>
    )
}

export default Experts;