import React from 'react';

import { HashLink as Link } from 'react-router-hash-link';

const Nav = () => {
    return (
        <div className="main-menu text-center">
            <nav id="mobile-menu">
                <ul>
                <li><Link to="/">Home</Link></li>
                    <li>
                        <Link to="#">OLA</Link>
                        <ul className="submenu">
                            <li><Link to="/about">About OLA</Link></li>
                            <li><Link to="/about-olacircles">About OLACircles</Link></li>
                            <li><Link to="/#our-community">Our Community</Link></li>

                            <li><Link to="/resources">Resources </Link></li>
                        </ul>
                    </li>
                    <li><Link to="/material-recovery">Material Recovery</Link>
                        
                            {/* <li><Link to="/about-facility">About the Facility​</Link></li> */}
                           

                  
                    </li>
                    {/* <li><Link to="#"> Water Circularity </Link> */}
                        {/* <ul className="submenu">
                            <li><Link to="/">Project 1</Link></li>
                            <li><Link to="/">Project 2</Link></li>
                            <li><Link to="/">Project Details</Link></li>
                            <li><Link to="/">Team</Link></li>
                            <li><Link to="/">Gallery</Link></li>
                            <li><Link to="/">Pricing</Link></li>
                            <li><Link to="/">FAQ</Link></li>
                            <li><Link to="/">404</Link></li>
                        </ul> */}
                    {/* </li> */}
                    <li><Link to="/success-stories">Get Inspired ​</Link>
                        
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Nav;