import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const PointOfCollectionData = [
    {
        id: uuidv4(),
        heading: 'Location',
        description: 'Charhabil Area',
    },
    {
        id: uuidv4(),
        heading: 'Collection Schedule',
        description: (
            <div>
                Monday-Wednesday: 8:00 AM - 4:00 PM
                <div className="collection-schedule-description">Saturday: 8:00 AM - 12:00 PM</div>
            </div>
        ),
    },
];

const PointOfCollectionItem = ({ heading, description, icon, link, style }) => {
    return (
        <div className={`col-xl-3 col-lg-3 col-md-6 d-flex flex-column align-items-center mb-4 point-of-collection-item-container`} style={style}>
            <h3 className="point-of-collection-heading">{heading}</h3>
            <div className="point-of-collection">
                <div className="point-of-collection-content b-color-6">
                    <p>{description}</p>
                </div>
            </div>
        </div>
    );
};

const PointOfCollection = () => {
    return (
        <div className="point-of-collection-section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-12">
                        <div className="point-of-collection-title text-center">
                            <h2 className="point-of-collection-heading-title">Point of Collection</h2>
                            <p>
                                To make recycling convenient and accessible for our local community, the Bqosta Material Recovery Facility has established a designated collection point in the Charhabil area. A specially equipped bus serves as the collection hub, where individuals can drop off their solid waste and contribute to our collective efforts in promoting a circular economy.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    {PointOfCollectionData.map((data, index) => (
                        <PointOfCollectionItem
                            key={data.id}
                            heading={data.heading}
                            description={data.description}
                            link={data.link}
                        />
                    ))}
                    <p>
                        The bus is staffed with friendly personnel who are available to assist with inquiries, help with registration, and ensure that your waste is properly sorted and prepared for recycling. The lounge area provides a comfortable space for community members to ask questions and learn more about our recycling process and the importance of maintaining clean, uncontaminated recyclables.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default PointOfCollection;
