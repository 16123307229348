import React from 'react';
import HeaderBottomOne from '../common/header/HeaderBottomOne';
import FooterOne from '../common/footer/FooterOne';
import OlaResources from '../components/about/OlaResources';





const Resources = () => {
    return (
        <>
            <HeaderBottomOne />
         <OlaResources/>
            <FooterOne />
            
        </>
    )
}

export default Resources;

