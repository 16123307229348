import React from 'react';
import GAC from '../../assets/images/blog/gac.mp4';

const BlogContent = () => {
    return (
        <div className="blog-details pb-10 mb-30 border-bottom-1">
            <div className="full-width-video">
                <video
                    src={GAC}
                    alt="GAC Rally Video"
                    loop
                    autoPlay
                    muted
                    className="video-style"
                />
            </div>
            <div className="blog-details-content">
                <h2 className="blog-title">GAC Rally</h2>
                <p>
                    In pursuit of enhancing the knowledge exchange among Green Ambassadors of Saida and promoting a culture of expression and exploration in the Circular Economy, climate action, and climate diplomacy. The rally included interactive and exploratory mechanisms, where the students presented their environmental achievements within their Green Clubs, furthermore, students explored the functionality of Bqosta Recovery Material Facility and contributed to the sorting.
                </p>
            </div>
        </div>
    );
}

export default BlogContent;
