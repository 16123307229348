import React from 'react';
import WhyChooseUsBg from '../../assets/images/why-choose-us/bg1.png';
import { Link } from 'react-router-dom';

const AboutFacilitySub = () => {
    return (
        <>
            <div className="about-facility-container bg-property" style={{
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(${WhyChooseUsBg})`,
                backgroundSize: 'cover',      // Ensure the background covers the entire container
                backgroundPosition: 'center'  // Center the image
            }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-12">
                            <div className="section-title about-facility-title-container">
                                <h2>About the Facility</h2>
                                <span className="about-facility-description">
                                    Welcome to the Bqosta Material Recovery Facility, a pioneering initiative dedicated to solid waste management that is at the heart of promoting circular economy practices. Our mission is to demonstrate the power and potential of community-based public partnerships in embedding circularity into waste management systems. By working closely with local communities and public entities, we aim to create a model that can be replicated across other regions, showcasing how collaboration can lead to sustainable waste management solutions. The Bqosta Facility is more than just a place for waste processing; it is a catalyst for cultural change. We are committed to shifting perceptions and practices surrounding waste management, encouraging communities to adopt responsible lifestyle behaviors that support sustainability. Through education, engagement, and active participation, we are fostering a community that sees waste not as a problem, but as an opportunity for innovation and environmental stewardship. Join us in our journey towards a circular economy, where waste is transformed into a resource, and every member of the community plays a vital role in building a sustainable future.
                                </span>
                                <Link to="/about-facility" className="about-facility-btn">Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutFacilitySub;
