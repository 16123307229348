import React, { useState } from 'react';
import { FaPlay, FaTimes } from 'react-icons/fa';
import CapacityBuilding from '../../assets/images/about/OLA.mp4';

const AboutVideoBlock = () => {
    const [isOpen, setOpen] = useState(false);

    return (
        <>
            {/* Custom Modal for Video */}
            {isOpen && (
                <div className="about-ola-modal-overlay" onClick={() => setOpen(false)}>
                    {/* Close Button */}
                    <button className="about-ola-modal-close-button" onClick={() => setOpen(false)}>
                        <FaTimes />
                    </button>
                    <video
                        className="about-ola-modal-video"
                        src={CapacityBuilding}
                        controls
                        autoPlay
                        onClick={(e) => e.stopPropagation()} // Prevent modal from closing when clicking on the video
                    />
                </div>
            )}

            <div className="about-ola-block pt-10 pb-10">
                <div className="container">
                    <div className="row align-items-center">
                        {/* Text Section */}
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="about-text-wrapper">
                                <div className="section-title mr-100 mb-40">
                                    <div className="about-ola-text">
                                        <span>
                                            In 2023, OLA has turned into a Green Academy at the Hariri Foundation, promoting environmental sustainability and capitalizing on greening education and capacity building on green transitions adaptive to climate change.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Video Section */}
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="about-ola-video-wrapper">
                                <video className="about-ola-video" src={CapacityBuilding} alt="" />
                                <button className="about-ola-play-button" onClick={() => setOpen(true)}>
                                    <FaPlay className="about-ola-play-icon" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutVideoBlock;
