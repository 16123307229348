import React from 'react';

const ErrorContent = () => {
    return (
        <>
            <div className="error-area">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="error-404-wrapper">
                                <iframe
                                    src="https://lottie.host/embed/f26b609f-28fc-4a66-bc0f-d6fb355e93c2/DxIQjgiaL9.json"
                                    title="Error Animation"
                                ></iframe>
                                <h3>Page Not Found</h3>
                                <p>The page you are looking for might have been removed had its <br /> name changed or is temporarily unavailable</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ErrorContent;
