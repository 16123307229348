import React from 'react';
import HeaderBottomOne from '../common/header/HeaderBottomOne';
import FooterOne from '../common/footer/FooterOne';
import AboutBqostaOne from '../components/about/AboutBqostaOne';
import AboutBqostaTwo from '../components/about/AboutBqostaTwo';
import InteractiveMap2 from '../components/home1/InteractiveMap2';



const AboutFacility = () => {
    return (
        <>
            <HeaderBottomOne />
            <AboutBqostaOne/>
            <AboutBqostaTwo/>
      <InteractiveMap2/>

            <FooterOne />

        </>
    )
}

export default AboutFacility;