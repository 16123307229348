import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import { FaPlay } from "react-icons/fa";
import Circular from '../../assets/images/why-choose-us/bg1.png';

const HeroStyleOne = () => {
    const [isOpen, setOpen] = useState(false);

    return (
        <>
            <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="zCRKvDyyHmI"
                onClose={() => setOpen(false)}
            />
            <div className="hero-area d-flex align-items-center" data-opacity="7">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="hero-wrapper">
                                <h1>
                                    <div className="hero-title-container">
                                        <span className="hero-title1">Welcome to </span>
                                        <div className='hero-title2-3'>
                                            <span className="hero-title2">
                                                OLA<span className="hero-title3">Circles</span>
                                            </span>
                                        </div>
                                    </div>
                                    <span className="hero-subtitle1">
                                        A Circular Hub by the Hariri Foundation
                                    </span>
                                    <p className="hero-subtitle2">
                                    where we provide second chances so that that today's wastes are tomorrow's raw material
                                    </p>
                                </h1>
                                <Link to="/about-olacircles" className="hero-btn">Read More</Link>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="hero-popup-content">
                                {/* Display the video thumbnail with leaf shape */}
                                <img src={Circular} alt="Video Thumbnail" className="leaf-thumbnail rotating-image" />
                                <div className="hero-popup-video">
                                    <button
                                        className="popup-video pulse-effect"
                                        onClick={() => setOpen(true)}>
                                        <FaPlay />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeroStyleOne;
