import React from 'react';
import HeaderBottomOne from '../common/header/HeaderBottomOne';
import ErrorContent from '../components/error/ErrorContent';
import FooterOne from '../common/footer/FooterOne';

const Error = () => {
    return (
            <>
                {/* <HeaderBottomOne /> */}

                <ErrorContent />
                {/* <FooterOne /> */}
            </>
    )
}

export default Error;