import React from 'react';
import CountUp from 'react-countup';

const FunFact = () => {
    return (
        <div className="fun-fact-area pt-30 pb-30 theme-bg-1">
            <div className="container">
                <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-3">
                        <div className="single-counter text-center">
                            <h2><span className="counter"><CountUp end={100} /></span></h2>
                            <p>Youth inspired</p>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3">
                        <div className="single-counter text-center">
                            <h2><span className="counter"><CountUp end={10} /></span></h2>
                            <p>Awareness Sessions  Done </p>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3">
                        <div className="single-counter text-center">
                            <h2><span className="counter"><CountUp end={39} /></span></h2>
                            <p>Entities Involved </p>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3">
                        <div className="single-counter text-center">
                            <h2><span className="counter"><CountUp end={8} /></span></h2>
                            <p>Experts & Partners</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FunFact;