import React, { useEffect } from "react";
import HeaderBottomOne from "../common/header/HeaderBottomOne";
import HeroStyleOne from "../components/hero/HeroStyleOne";
import ServiceStyleOne from "../components/services/ServiceStyleOne";
import FunFact from "../components/fun-fact/FunFact";
import OurCommunity from "../components/why-choose-us/WhyChoseUsOne";
import InteractiveMap from "../components/home1/InteractiveMap";
import FooterOne from "../common/footer/FooterOne";
import HomeTwoAbout from "../components/home2/HomeTwoAbout";


const HomeDefault = () => {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, []);

    return (
        <>
            <HeaderBottomOne />
            <HeroStyleOne />
            <FunFact />
            <HomeTwoAbout />
    
            <ServiceStyleOne />
            <OurCommunity />
            <InteractiveMap />
            <FooterOne />
        </>
    )
}

export default HomeDefault;