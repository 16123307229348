import React from 'react';
import { Link } from 'react-router-dom';

const AboutFacilitySub = () => {
    return (
        <>
            <div className="goal-ola-container height-450 pt-20">
                <div className="goal-ola-background"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-12">
                            <div className="ola-text pt-30">
                                <h1>
                                    <span className="ola-title">Goal of </span>
                                    <span className="ola-title-bold">
                                        OLA<span className="ola-title">Circles ?</span>
                                    </span>
                                    <br />
                                </h1>
                                <p className="goal-ola-description">
                                    OLACircles highlight circularity in sorting at source, recycling, upcycling, and promoting sustainable production and consumption. It also aims to enhance collaboration between partners, both institutions and individuals, to encourage related initiatives. Targeting youth and local communities of Saida, the program includes awareness campaigns, offering specialized skills, and engaging communities in training and participatory activities to apply these practices.<br />
                                    Which kind of Platform it represents?<br />
                                    For the OLACircles platform, it is a digital space dedicated to institutions and individuals to ensure collective responsibility in sorting non-organic waste at the source. This digital space monitors sorting activities, tracks the quantities and types of waste collected, and contributes to identifying areas for improvement and informed decision-making. Furthermore, this platform is a space for knowledge sharing and for the community to track all our activities in circularity on a local and regional level.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutFacilitySub;
