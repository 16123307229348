import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import WhyChooseUsBg from '../../assets/images/why-choose-us/bg1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const WhyChooseUsOneData = [
  {
    id: uuidv4(),
    heading: 'Experts',
    description:
      'Our experts represent different disciplines of circularity, bringing a unique blend of expertise and passion to our mission. Whether through research, practical implementation, or advocacy, they are at the forefront of transforming ideas into impactful actions.',
    link: '/experts',
  },
  {
    id: uuidv4(),
    heading: 'Partners',
    description:
      'Our partners are leaders in their respective domains, bring a wealth of knowledge and resources that complement our efforts to drive sustainability and innovation.',
    link: '/partners',
  },
  {
    id: uuidv4(),
    heading: 'OLA Team',
    description:
      'Committed to excellence and driven by a shared vision, our team is at the heart of everything we do, ensuring that we not only meet our objectives but also inspire others to join us on this journey towards a more sustainable future.',
    link: '/team',
  },
];

const WhyChooseUsOneItem = ({ heading, description, icon, link, customStyleClass, descriptionStyleClass }) => {
  return (
    <div className={`col-xl-3 col-lg-3 col-md-6 d-flex flex-column align-items-center mb-4 ${customStyleClass}`}>
      <h3 className="choose-us-heading">{heading}</h3>
      <div className="single-choose-us">
        <div className="single-choose-us-icon">
          <FontAwesomeIcon
            icon={icon}
            className="icon-style" // Apply new class name
          />
        </div>
        <div className="single-choose-us-content b-color-6">
          <p className={descriptionStyleClass}>{description}</p>
          <Link to={link} className="read-more">
            Read More <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </div>
      </div>
    </div>
  );
};

const WhyChoseUsOne = () => {
  const margins = ['20px', '70px', '40px'];

  return (
    <div id={"our-community"} className="why-choose-us-wrapper bg-image">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-12">
            <div className="section-title text-center">
              <h2 className="section-heading">Our Community</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {WhyChooseUsOneData.map((data, index) => (
            <WhyChooseUsOneItem
              key={data.id}
              heading={data.heading}
              description={data.description}
              link={data.link}
              customStyleClass={`custom-style-${index}`}
              descriptionStyleClass={`description-style-${index}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyChoseUsOne;
