import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faRecycle, faBeer, faBatteryFull } from '@fortawesome/free-solid-svg-icons'; // Updated icons

const WhyChooseUsOneData = [
    {
        id: uuidv4(),
        heading: 'Papers and Cardboards',
        description: 'Newspapers, magazines, office paper, cardboard boxes, and similar paper products. Please ensure that all paper and cardboard items are free from food residue, oil stains, or any organic waste.',
        icon: faFileAlt,  // Updated icon
    },
    {
        id: uuidv4(),
        heading: 'Plastics',
        description: 'Bottles, containers, packaging, and other plastic items. We accept various types of plastics, including PET, HDPE, and LDPE. All plastics must be clean and rinsed, with no traces of food, oils, or organic material.',
        icon: faRecycle,  // Updated icon
    },
    {
        id: uuidv4(),
        heading: 'Metals and Cans',
        description: 'Aluminum cans, tin cans, steel containers, and other metal products. Please make sure all metal items are clean and dry, free from food scraps, oils, or liquids.',
        icon: faBeer,  // Updated icon
    },
    {
        id: uuidv4(),
        heading: 'Electronics and Batteries',
        description: 'Small electronic devices, batteries, and accessories. Before recycling, please remove any batteries from electronic devices and ensure they are free from leaks or damage.',
        icon: faBatteryFull,  // Updated icon
    },
];

const AcceptedRecyclablesItem = ({ heading, description, icon }) => {
    return (
        <div className="accepted-recyclables-item">
            <h3>{heading}</h3>
            <div className="icon-wrapper">
                <div className="rotating-border"></div>
                <div className="icon-container">
                    <FontAwesomeIcon icon={icon} />
                </div>
            </div>
            <div>
                <p>{description}</p>
            </div>
        </div>
    );
};

const AcceptedRecyclables = () => {
    return (
        <div className="accepted-recyclables-container">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-12">
                        <div className="accepted-recyclables-title">
                            <h2>Accepted Recyclables</h2>
                            <p>At the Bqosta Material Recovery Facility, we are committed to maximizing the recovery of valuable materials from solid waste. To ensure the efficiency of our recycling process and the quality of the recovered materials, we accept the following types of recyclables</p>
                        </div>
                    </div>
                </div>
                {/* Grid container for items */}
                <div className="accepted-recyclables-grid">
                    {WhyChooseUsOneData.map((data) => (
                        <AcceptedRecyclablesItem
                            key={data.id}
                            heading={data.heading}
                            description={data.description}
                            icon={data.icon}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AcceptedRecyclables;
