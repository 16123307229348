import React from 'react';
import HeaderBottomOne from '../common/header/HeaderBottomOne';
import FooterOne from '../common/footer/FooterOne';

import WhatIsCircularity from '../components/about/WhatIsCircularity';
import WhatIsOla from '../components/about/WhatIsOla';
import GoalOlaCircles from '../components/about/GoalOlaCircles';



const AboutOlaCircles = () => {
    return (
        <>
            <HeaderBottomOne />
          
            <WhatIsOla/>
            <WhatIsCircularity/>
            <GoalOlaCircles/>


            <FooterOne />

        </>
    )
}

export default AboutOlaCircles;